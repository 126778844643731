<template>
  <div
    class="h-auto w-[260px] flex flex-col py-[22px] px-[20px] border-[1px] bg-[#FFFFFF] border-[#F2B679] rounded-[10px]"
  >
    <div class="flex">
      <div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.9415 2.04255C14.4066 1.87031 12.8588 2.22416 11.5513 3.04621C10.2437 3.86825 9.25399 5.1098 8.744 6.56763C8.29856 7.84095 8.24216 9.21339 8.5748 10.5118L2.26484 16.8123C2.09403 16.9829 1.99805 17.2144 1.99805 17.4558V21.0928C1.99805 21.595 2.40514 22.0021 2.90731 22.0021H6.54435C6.7855 22.0021 7.01678 21.9063 7.1873 21.7358L9.00582 19.9172C9.17634 19.7467 9.27213 19.5154 9.27213 19.2743V17.8324L10.558 16.5465H11.9999C12.2411 16.5465 12.4723 16.4507 12.6429 16.2802L13.4956 15.4274C14.7948 15.7588 16.1677 15.7008 17.4408 15.2536C18.898 14.7418 20.1383 13.7505 20.9587 12.4419C21.7791 11.1334 22.1311 9.58506 21.9569 8.05045C21.7827 6.51583 21.0928 5.08579 20 3.99437C18.9072 2.90295 17.4763 2.2148 15.9415 2.04255ZM12.5192 4.58574C13.478 3.98291 14.6131 3.72342 15.7387 3.84973C16.8642 3.97604 17.9135 4.48069 18.7149 5.28106C19.5163 6.08143 20.0223 7.13013 20.15 8.25552C20.2777 9.3809 20.0196 10.5163 19.418 11.4759C18.8164 12.4355 17.9068 13.1625 16.8382 13.5378C15.7696 13.9132 14.6052 13.9146 13.5357 13.542C13.2062 13.4272 12.8402 13.5111 12.5936 13.7577L11.6233 14.728H10.1814C9.94024 14.728 9.70897 14.8238 9.53845 14.9943L7.71993 16.8128C7.54941 16.9833 7.45361 17.2146 7.45361 17.4558V18.8977L6.16772 20.1835H3.81657V17.8328L10.2447 11.4143C10.492 11.1674 10.5759 10.8005 10.4605 10.4707C10.0865 9.40157 10.0865 8.2372 10.4605 7.16812C10.8345 6.09904 11.5603 5.18858 12.5192 4.58574ZM16.5463 5.63672C15.542 5.63672 14.7278 6.4509 14.7278 7.45524C14.7278 8.45958 15.542 9.27376 16.5463 9.27376C17.5506 9.27376 18.3648 8.45958 18.3648 7.45524C18.3648 6.4509 17.5506 5.63672 16.5463 5.63672Z"
            fill="#4C4C4D"
          />
        </svg>
      </div>
      <div class="flex ml-[14px] flex-col">
        <div>
          <p class="font-[500] text-[14px] leading-[20px]">
            {{ getTarifTitle }}
          </p>
          <p class="pt-[14px]">
            {{ getTarifText }}
          </p>
        </div>
        <a
          target="_blank"
          href="https://www.leadertask.ru/alpha"
          class="flex items-center mt-[14px] h-[30px] w-[188px] justify-center bg-[#F2B679] rounded-[6px]"
        >
          Купить
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tarif: {
      type: String,
      default: ''
    }
  },
  computed: {
    getTarifText () {
      switch (this.tarif) {
        case 'free':
        case 'alpha':
          return 'Купите лицензию, чтобы использовать все функции приложения'
        case 'trial':
          return 'Вам доступны все функции приложения. По истечении срока вы перейдет в режим ограниченной функциональности'
        default:
          return ''
      }
    },
    getTarifTitle () {
      switch (this.tarif) {
        case 'free':
          return 'Истекла лицензия'
        case 'alpha':
          return 'Истекла лицензия'
        case 'trial':
          return 'Пробный период'
        default:
          return ''
      }
    }
  },
  methods: {
    redirect () {
      if (this.link.length) {
        window.open(this.link)
      }
    }
  }
}
</script>
