<template>
  <div
    class="flex justify-between"
    :style="{ borderColor: colors[task.uid_marker] ? colors[task.uid_marker].back_color : ''}"
    :class="{
      'bg-gray-200 dark:bg-gray-800':
        isTaskComplete &&
        task.uid_marker !== '00000000-0000-0000-0000-000000000000'
    }"
  >
    <DoitnowStatusModal
      v-if="showStatusModal"
      :title="'Внимание'"
      :text="'При завершении этой задачи все подзадачи будут завершены. Завершить?'"
      @cancel="showStatusModal = false"
      @yes="changeStatus(lastSelectedStatus, true)"
    />

    <div
      v-if="task.mode === 'slide' && task.visible"
      class="pt-[45px] pb-6 px-5 w-full bg-white rounded-lg"
    >
      <SlideBody
        :name="task.name"
        @nextTask="nextTask"
      />
    </div>
    <div
      v-else
      class="py-6 px-5 w-[85%] bg-white rounded-lg mr-[10px]"
    >
      <div
        class="flex mr-[30px] justify-between items-center mb-6 p-2 rounded-[8px] pl-4"
        :style="{ backgroundColor: colors[task.uid_marker] ? colors[task.uid_marker].back_color : '', color: getValidForeColor(colors[task.uid_marker]?.fore_color) }"
      >
        <!-- task info/status -->
        <div class="flex items-center -ml-2">
          <TaskStatus
            :task="task"
            @changeStatus="changeStatus"
          />
          <contenteditable
            v-model="name"
            v-linkify:options="{ className: 'text-blue-600', tagName: 'a' }"
            tag="div"
            class="taskName p-0.5 ring-0 outline-none max-w-7xl mt-0.5 ml-1 flex overflow-x-hidden"
            style="word-break: break-word"
            :contenteditable="task._isEditable"
            placeholder="Введите название задачи"
            :no-nl="false"
            :no-html="false"
            :style="{ color: getValidForeColor(colors[task.uid_marker]?.fore_color) }"
            :class="{ 'uppercase': !task._isEditable && colors[task.uid_marker] && colors[task.uid_marker].uppercase, 'text-gray-500': task.status == TASK_STATUS.TASK_COMPLETED || task.status == TASK_STATUS.TASK_CANCELLED, 'line-through': task.status == TASK_STATUS.TASK_COMPLETED || task.status == TASK_STATUS.TASK_CANCELLED }"
            @focusout="clearTaskFocus(task)"
            @dblclick.stop="editTaskName(task)"
            @keydown.enter="updateTask($event, task)"
          />
        </div>
        <DoitnowTaskButtonDots
          :date-create="selectedTask?.date_create"
          :only-files="showOnlyFiles"
          @copyUrl="copyUrl(task)"
          @toggleFiles="showOnlyFiles = !showOnlyFiles"
        />
      </div>
      <div class="flex text-sm text-left justify-between w-[400px]">
        <div class="flex flex-col font-medium w-[720px]">
          <div
            v-if="task.uid && task.emails.includes(user?.current_user_email) && task.uid_performer !== user?.current_user_uid"
            class="border-[#FF912380] w-[150px] text-center py-1 px-2 mb-2 border-2 rounded-[8px] inline-block"
          >
            Задача в доступе
          </div>
          <!-- customer -->
          <div
            v-if="task.uid"
            v-show="task.type !== 1"
            class="flex mb-2"
          >
            <span
              class="mr-2 w-[90px] shrink-0"
            >
              Заказчик:
            </span>
            <div
              class="flex"
            >
              <div
                v-if="!employees[task.uid_customer]?.fotolink"
              >
                <svg
                  class="rounded-lg ml-1 h-[20px] w-[20px]"
                  width="20"
                  height="20"
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="42"
                    height="42"
                    rx="8"
                    fill="#ded9d9"
                  />
                  <path
                    d="M15.75 14.583C15.75 17.4775 18.1055 19.833 21 19.833C23.8945 19.833 26.25 17.4775 26.25 14.583C26.25 11.6885 23.8945 9.33301 21 9.33301C18.1055 9.33301 15.75 11.6885 15.75 14.583ZM30.3333 31.4997H31.5V30.333C31.5 25.8308 27.8355 22.1663 23.3333 22.1663H18.6667C14.1633 22.1663 10.5 25.8308 10.5 30.333V31.4997H30.3333Z"
                    fill="#ed3b18"
                  />
                </svg>
              </div>
              <img
                v-else
                :alt="employees[task.uid_customer]?.name"
                :src="employees[task.uid_customer]?.fotolink"
                class="rounded-lg ml-1 h-[20px] w-[20px]"
              >
              <span class="ml-1 text-black">{{ getByNameOrEmail(employees) }}</span>
            </div>
          </div>
          <!-- performer -->
          <div
            v-if="task.uid"
            v-show="(task.type !== 1) && (task.uid_performer !== task.uid_customer)"
            class="flex mb-2"
          >
            <span
              class="mr-2 w-[90px] shrink-0"
            >
              Исполнитель:
            </span>
            <div
              class="flex"
            >
              <img
                :alt="employees[task.uid_performer]?.name"
                :src="employees[task.uid_performer] ? employees[task.uid_performer]?.fotolink : ''"
                class="rounded-lg ml-1 h-[20px] w-[20px]"
              >
              <span class="ml-1 text-black">{{ employees[task.uid_performer]?.name }}</span>
            </div>
          </div>
          <!-- days -->
          <div
            v-if="task.uid"
            v-show="dateClearWords"
            class="flex mb-2"
          >
            <span
              class="mr-2 w-[90px] shrink-0"
            >
              Срок:
            </span>
            <div
              v-show="dateClearWords"
              class="flex"
            >
              <span class="text-black">{{ dateClearWords + getTime }}</span>
            </div>
          </div>
          <!-- overdue -->
          <div
            v-if="task.uid"
            v-show="plural"
            class="flex mb-2"
          >
            <span class="mr-2 w-[90px] shrink-0">
              Просрочено:
            </span>
            <div
              class="flex"
            >
              <span class="text-red-500">{{ plural }}</span>
            </div>
          </div>
          <!-- project -->
          <div
            v-if="task.uid && projects[task.uid_project]"
            class="flex mb-2"
          >
            <span
              class="mr-2 w-[90px] shrink-0"
            >
              Проект:
            </span>
            <div
              class="flex mb-2"
            >
              <span class="text-black overflow-hidden truncate">{{
                projects[task.uid_project]?.name.length > 99
                  ? projects[task.uid_project]?.name.split('').slice(0,100).join('') + '...'
                  : projects[task.uid_project]?.name
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <TaskPropsCommentEditor
        v-if="task.uid"
        v-show="task.comment.length || task.uid_customer === user?.current_user_uid"
        class="mt-3"
        style="word-break: break-word"
        :comment="task.comment"
        :can-edit="task.uid_customer === user?.current_user_uid"
        @changeComment="onChangeComment"
      />
      <Checklist
        v-if="task?.checklist || checklistshow || checklistSavedNow"
        class="mt-3 checklist-custom font-medium"
        :checklist="task?.checklist"
        :can-edit="canEditChecklist"
        :can-check="canCheckChecklist"
        :add-new="checklistshow"
        @changeChecklist="onChangeChecklist"
        @endEdit="onAddChecklistComplete"
      />
      <div
        v-if="task.uid"
        class="max-w-1/2 border-t mt-2 pt-2"
        :class="task.uid_marker !== '00000000-0000-0000-0000-000000000000' ? 'bg-white p-1 mt-1 rounded-lg' : ''"
      >
        <div class="mx-auto max-w-[540px]">
          <div
            v-if="currentAnswerMessageUid !== ''"
            class="quote-request border-l-2 border-[#7E7E80] mt-[8px] h-[40px]"
          >
            <div class="flex flex-row items-center">
              <div class="grow w-[calc(100%-20px)]">
                <div
                  class="mx-[4px]"
                >
                  <p class="text-[11px] leading-[16px] overflow-hidden text-black text-ellipsis whitespace-nowrap">
                    <!-- Кому отвечают -->
                    {{ messageQuoteUser }}
                  </p>
                  <p class="text-[12px] leading-[16px] overflow-hidden text-[#9a9fa6] text-ellipsis whitespace-nowrap">
                    <!-- Сообщение на которое отвечают -->
                    {{ messageQuoteString }}
                  </p>
                </div>
              </div>
              <div
                class="flex-none"
                @click="onAnswerMessage('')"
              >
                <svg
                  class="m-[2px]"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.28481 4.30107C4.67082 3.90608 5.30395 3.8988 5.69893 4.28481L9.25 7.75517L12.8011 4.28481C13.1961 3.8988 13.8292 3.90608 14.2152 4.30107C14.6012 4.69605 14.5939 5.32918 14.1989 5.71519L10.6808 9.15341L14.1989 12.5916C14.5939 12.9776 14.6012 13.6108 14.2152 14.0058C13.8292 14.4007 13.1961 14.408 12.8011 14.022L9.25 10.5516L5.69893 14.022C5.30395 14.408 4.67082 14.4007 4.28481 14.0058C3.8988 13.6108 3.90608 12.9776 4.30107 12.5916L7.81925 9.15341L4.30107 5.71519C3.90608 5.32918 3.8988 4.69605 4.28481 4.30107Z"
                    fill="#999999"
                  />
                </svg>
              </div>
            </div>
          </div>
          <!-- input -->
          <TaskPropsInputForm
            :task="task"
            :answer="currentAnswerMessageUid"
            @readTask="readTask"
            @removeAnswerHint="removeAnswerHint"
          />
          <MessageSkeleton v-if="isTaskMessagesLoading" />
          <!-- chat -->
          <TaskPropsChatMessages
            v-else-if="taskMessages?.length"
            id="content"
            class="mt-3"
            :task="task"
            :task-messages="taskMessages"
            :current-user-uid="user?.current_user_uid"
            :show-all-messages="true"
            :show-only-files="showOnlyFiles"
            @answerMessage="onAnswerMessage"
            @sendTaskMsg="sendTaskMsg"
            @onPasteEvent="onPasteEvent"
            @deleteFiles="deleteFiles"
            @deleteTaskMsg="deleteTaskMsg"
            @readTask="readTask"
          />
        </div>
      </div>
    </div>
    <!-- accept/redo/decline -->
    <div
      v-if="task && !task.visible"
      class="flex ml-[10px] flex-col min-w-[200px] items-center"
    >
      <div
        class="rounded-lg hover:cursor-pointer hover:bg-gray-300 text-sm bg-opacity-70 font-medium flex w-[221px] h-[40px] items-center bg-white mb-[20px] whitespace-nowrap text-center text-[#424242] "
        @click="nextTask"
      >
        <span class="ml-[40px] w-[70px]">Следующая задача</span>
        <Icon
          class="ml-[68px]"
          :height="arrowForw.height"
          :width="arrowForw.width"
          :box="arrowForw.viewBox"
          :path="arrowForw.path"
        />
      </div>
      <!-- accept -->
      <div
        v-if="task.mode !== 'slide' || task.uid_customer === user?.current_user_uid || task.uid_performer === user?.current_user_uid"
        class="flex hover:cursor-pointer items-center text-sm hover:bg-[#0000000a] font-medium min-h-[40px] w-[221px] rounded-lg mb-2 pl-[22px] whitespace-nowrap text-[#3e3e3f]"
        @click="accept"
      >
        <div class="w-[16px] h-[16px] flex items-center justify-center">
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3337 1L5.00033 8.33333L1.66699 5"
              stroke="#4C4C4D"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <span
          class="ml-[10px] w-[70px]"
        >{{ acceptBtnText }}</span>
      </div>
      <!-- redo -->
      <div
        v-if="task.uid_customer === user?.current_user_uid || task.uid_performer === user?.current_user_uid"
        class="flex hover:cursor-pointer items-center text-sm hover:bg-[#0000000a] font-medium min-h-[40px] w-[221px] rounded-lg mb-2 pl-[22px] whitespace-nowrap text-[#3e3e3f]"
        @click="reDo"
      >
        <div class="w-[16px] h-[16px] flex items-center justify-center">
          <Icon
            :path="cancelImproveRejectIcon('path')"
            :width="cancelImproveRejectIcon('width')"
            :height="cancelImproveRejectIcon('height')"
            :box="cancelImproveRejectIcon('viewBox')"
          />
        </div>
        <span
          class="ml-[10px] w-[70px]"
        >{{ task.uid_customer === user?.current_user_uid ? (task.uid_performer === user?.current_user_uid ? 'Отменить' : 'На доработку') : 'Отклонить'
        }}</span>
      </div>
      <!-- decline -->
      <div
        v-if="task.uid_customer === user?.current_user_uid || task.uid_performer === user?.current_user_uid"
        class="flex hover:cursor-pointer items-center text-sm hover:bg-[#0000000a] font-medium min-h-[40px] w-[221px] rounded-lg mb-2 pl-[22px] whitespace-nowrap text-[#3e3e3f]"
        @click="decline"
      >
        <div class="w-[16px] h-[16px] flex items-center justify-center">
          <Icon
            :path="pauseD.path"
            :width="pauseD.width"
            :height="pauseD.height"
            :box="pauseD.viewBox"
          />
        </div>
        <span class="ml-[10px] w-[70px]">Отложить</span>
      </div>
      <PerformButton
        v-if="task.status !== TASK_STATUS.NOTE && task.type !== TASK_STATUS.TASK_IN_WORK && (task.uid_customer === user?.current_user_uid || task.uid_customer === task.uid_performer)"
        class="hover:cursor-pointer"
        :task-type="task.type"
        :current-user-uid="user?.current_user_uid"
        :performer-email="task.email_performer"
        @changePerformer="onChangePerformer"
        @reAssign="onReAssignToUser"
      />
      <!-- Change access -->
      <div
        v-if="task.status !== TASK_STATUS.NOTE && (task.type !== TASK_STATUS.TASK_IN_WORK || task.emails.includes(user?.current_user_email)) && task.uid_customer !== user?.current_user_uid && task.uid_performer !== user?.current_user_uid && task.mode !== 'slide'"
        class="flex hover:cursor-pointer items-center text-sm hover:bg-[#0000000a] font-medium min-h-[40px] w-[221px] rounded-lg mb-2 pl-[22px] whitespace-nowrap text-[#3e3e3f]"
        @click="() => onChangeAccess(task.emails)"
      >
        <span
          class="ml-[11px] w-[70px]"
        >
          Выйти из доступа
        </span>
        <Icon
          :path="close.path"
          :width="close.width"
          :height="close.height"
          :box="close.viewBox"
          class="ml-5"
        />
      </div>
      <SetDate
        v-if="task.status !== TASK_STATUS.NOTE && task.type !== TASK_STATUS.TASK_IN_WORK && task.uid_customer === user?.current_user_uid"
        class="hover:cursor-pointer"
        :name="'Назначить срок'"
        :date-begin="task.date_begin"
        :date-end="task.date_end"
        :date-text="task.term_user"
        @changeDates="onChangeDates"
      />

      <div
        v-if="task.mode !== 'slide' || task.uid_customer === user?.current_user_uid || task.uid_performer === user?.current_user_uid"
        class="flex w-[221px] hover:cursor-pointer border border-transparent items-center text-sm hover:border hover:bg-[#0000000a] pl-[22px] font-medium min-h-[40px] rounded-lg text-[#3e3e3f] whitespace-nowrap text-end"
        @click="setTaskFromQueue(task.uid)"
      >
        <div class="w-[16px] h-[16px] flex items-center justify-center">
          <Icon
            :path="openTask.path"
            :width="openTask.width"
            :height="openTask.height"
            :box="openTask.viewBox"
          />
        </div>
        <span
          class="ml-[10px]"
        >
          Открыть задачу
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { copyText } from 'vue3-clipboard'
import { uuidv4 } from '@/helpers/functions'
import { TASK_STATUS } from '@/constants'
import contenteditable from 'vue-contenteditable'
import linkify from 'vue-linkify'
import TaskPropsCommentEditor from '@/components/TaskProperties/TaskPropsCommentEditor.vue'
import PerformButton from '@/components/Doitnow/PerformButton.vue'
import DoitnowStatusModal from '@/components/Doitnow/DoitnowStatusModal.vue'
import SetDate from '@/components/Doitnow/SetDate.vue'
import Checklist from '@/components/Doitnow/Checklist.vue'
import TaskPropsChatMessages from '@/components/TaskProperties/TaskPropsChatMessages.vue'
import TaskPropsInputForm from '@/components/TaskProperties/TaskPropsInputForm.vue'
import DoitnowTaskButtonDots from '@/components/Doitnow/DoitnowTaskButtonDots.vue'
import TaskStatus from '@/components/TasksList/TaskStatus.vue'
import Icon from '@/components/Icon.vue'
import SlideBody from '@/components/Doitnow/SlideBody.vue'
import MessageSkeleton from '../TaskProperties/MessageSkeleton.vue'

import * as INSPECTOR from '@/store/actions/inspector.js'
import * as TASK from '@/store/actions/tasks'
import * as MSG from '@/store/actions/taskmessages'
import * as FILES from '@/store/actions/taskfiles.js'

/* Icons */
import taskoptions from '@/icons/taskoptions.js'
import file from '@/icons/file.js'
import inaccess from '@/icons/inaccess.js'
import doublecheck from '@/icons/doublecheck.js'
import close from '@/icons/doitnow/close.js'
import pauseD from '@/icons/doitnow/pause.js'
import msgs from '@/icons/msgs.js'
import taskcomment from '@/icons/taskcomment.js'
import checklist from '@/icons/checklist.js'
import project from '@/icons/doitnow/project.js'
import openTask from '@/icons/doitnow/openTask.js'
import tagIcon from '@/icons/tag.js'
import performerRead from '@/icons/performer-read.js'
import performerNotRead from '@/icons/performer-not-read.js'
import taskfocus from '@/icons/taskfocus.js'
import check from '@/icons/doitnow/check.js'
import clock from '@/icons/clock.js'
import arrowForw from '@/icons/arrow-forw-sm.js'
// Statuses icons
import readyStatus from '@/icons/ready-status.js'
import note from '@/icons/note.js'
import inwork from '@/icons/inwork.js'
import pause from '@/icons/pause.js'
import canceled from '@/icons/canceled.js'
import improve from '@/icons/improve.js'
import repeat from '@/icons/repeat.js'
/* /Icons */

export default {
  components: {
    Icon,
    SetDate,
    TaskPropsChatMessages,
    TaskPropsCommentEditor,
    DoitnowTaskButtonDots,
    PerformButton,
    Checklist,
    TaskPropsInputForm,
    DoitnowStatusModal,
    contenteditable,
    TaskStatus,
    SlideBody,
    MessageSkeleton
  },
  directives: {
    linkify
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    subTasks: {
      type: Array,
      default: () => ([])
    },
    colors: {
      type: Object,
      default: () => ({})
    },
    employees: {
      type: Object,
      default: () => ({})
    },
    projects: {
      type: Object,
      default: () => ({})
    },
    user: {
      type: Object,
      default: () => ({})
    },
    taskMessages: {
      type: Array,
      default: () => ([])
    },
    childrens: {
      type: Array,
      default: () => ([])
    },
    isTaskMessagesLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['clickTask', 'nextTask', 'changeValue', 'readTask'],
  data (props) {
    return {
      // * variables * //
      isChatVisible: false,
      showStatusModal: false,
      lastSelectedStatus: '',
      showConfirm: false,
      checklistshow: false,
      checklistSavedNow: false,
      currentAnswerMessageUid: '',
      showAllMessages: false,
      name: props.task.name,
      isloading: false,
      showOnlyFiles: false,

      // * imports * //
      taskoptions,
      TASK_STATUS,
      close,
      file,
      pause,
      inaccess,
      msgs,
      pauseD,
      openTask,
      check,
      doublecheck,
      taskcomment,
      checklist,
      project,
      tagIcon,
      performerRead,
      performerNotRead,
      taskfocus,
      clock,
      readyStatus,
      note,
      inwork,
      canceled,
      improve,
      repeat,
      arrowForw
    }
  },
  computed: {
    canEditChecklist () {
      return ((this.task?.type === 1 || this.task?.type === 2) && this.user.tarif !== 'free') || !this.$store.getters.isLicenseExpired
    },
    canCheckChecklist () {
      return ((this.canEditChecklist || this.task?.type === 3) && this.user.tarif !== 'free') || !this.$store.getters.isLicenseExpired
    },
    selectedTask () {
      return this.$store.state.tasks.selectedTask
    },
    taskMessagesAndFiles () {
      return this.$store.state.taskfilesandmessages.messages
    },
    isCustomer () {
      return this.task.uid_customer === this.user?.current_user_uid
    },
    getTime () {
      let time
      if (this.isCustomer) {
        time = new Date(this.task.date_end)
      } else {
        time = new Date(this.task.customer_date_end)
      }
      let hours = String(time.getHours())
      let minutes = String(time.getMinutes())
      if (hours === '0') {
        hours += '0'
      } else if (minutes === '0') {
        minutes += '0'
      }
      if (!this.task.customer_date_end.includes('23:59:59')) {
        return '(' + hours + ':' + minutes + ')'
      } else {
        return ''
      }
    },
    acceptBtnText () {
      if (this.task.mode === 'slide') {
        return 'Понятно'
      } else if (this.task.uid_customer === this.user.current_user_uid && this.task.uid_performer === this.user.current_user_uid) {
        return 'Завершить'
      } else if (this.task.uid_customer === this.user.current_user_uid && this.task.uid_performer !== this.user.current_user_uid) {
        return 'Принять и завершить'
      } else {
        return 'Готово к сдаче'
      }
    },
    dateClearWords () {
      let time
      if (this.isCustomer) {
        time = this.task.date_end
      } else {
        time = this.task.customer_date_end
      }
      if (time === '0001-01-01T00:00:00') {
        return false
      }
      const month = new Date(time).getMonth() + 1
      const months = [' Января ', ' Февраля ', ' Марта ', ' Апреля ', ' Мая ', ' Июня ', ' Июля ', ' Августа ', ' Сентября ', ' Октября ', ' Ноября ', ' Декабря ']
      const date = new Date(time).getDate() + months[month - 1] + (new Date().getFullYear() === new Date(time).getUTCFullYear() ? '' : new Date(time).getUTCFullYear())
      return date
    },
    isAccessVisible () {
      if (this.task.emails) return true
      if (this.task.type === 1 || this.task.type === 2) return true
      return false
    },
    isPropertiesMobileExpanded () {
      return this.$store.state.isPropertiesMobileExpanded
    },
    computed () {
      return this.$store.state.projects
    },
    statusColor () {
      const statusColor = {
        4: 'text-green-600',
        5: 'text-red-600',
        8: 'text-red-600',
        9: 'text-blue-500'
      }
      return statusColor[this.task.status]
        ? statusColor[this.task.status]
        : 'text-gray-500 dark:text-gray-100'
    },
    isTaskComplete () {
      return this.task.status === TASK_STATUS.TASK_COMPLETED || this.task.status === TASK_STATUS.TASK_CANCELLED
    },
    backgroundColor () {
      return this.getValidBackColor(
        this.colors[this.task.uid_marker]?.back_color
      )
    },
    uppercase () {
      return this.colors[this.task.uid_marker]?.uppercase ?? false
    },
    plural () {
      let time
      if (this.isCustomer) {
        time = this.task.date_end
      } else {
        time = this.task.customer_date_end
      }
      if (time === '0001-01-01T00:00:00') {
        return false
      }
      const todayDate = new Date()
      const dateEnd = new Date(time)
      todayDate.setHours(0, 0, 0, 0)
      dateEnd.setHours(0, 0, 0, 0)
      const date = Math.floor((todayDate - dateEnd) / (60 * 60 * 24 * 1000))
      const dayName = date % 10 === 1 && date % 100 !== 11 ? 'день' : (((date >= 2) && (date % 10 <= 4)) && (date % 100 < 10 || date % 100 >= 20) ? 'дня' : 'дней')
      if (date === 0 || date < 0) {
        return false
      } else {
        return date + ' ' + dayName
      }
    },
    messageQuoteUser () {
      if (!this.currentAnswerMessageUid) return ''
      const quotedMessage = this.taskMessages.find(message => message.uid === this.currentAnswerMessageUid)
      if (!quotedMessage) return ''
      return this.employees[quotedMessage.uid_creator]?.name ?? '???'
    },
    messageQuoteString () {
      if (!this.currentAnswerMessageUid) return ''
      const quotedMessage = this.taskMessages.find(message => message.uid === this.currentAnswerMessageUid)
      if (!quotedMessage) return ''
      let msg = quotedMessage.msg.trim()
      msg = msg.replaceAll('&amp;', '&')
      msg = msg.replaceAll('&lt;', '<')
      msg = msg.replaceAll('&gt;', '>')
      return msg
    }
  },
  watch: {
    task () {
      this.showAllMessages = false
      this.isChatVisible = false
      this.name = this.task.name
    }
  },
  methods: {
    onChangeChecklist (checklist) {
      const data = {
        uid_task: this.task?.uid,
        checklist: checklist
      }
      this.checklistSavedNow = true
      this.$store.dispatch(TASK.CHANGE_TASK_CHECKLIST, data).then(
        resp => {
          this.$emit('changeValue', { checklist: checklist })
        }
      ).finally(() => {
        this.checklistSavedNow = false
      })
    },
    onAddChecklistComplete () {
      this.checklistshow = false
    },
    sendTaskMsg (msg) {
      const date = new Date()
      const month = this.pad2(date.getUTCMonth() + 1)
      const day = this.pad2(date.getUTCDate())
      const year = this.pad2(date.getUTCFullYear())
      const hours = this.pad2(date.getUTCHours())
      const minutes = this.pad2(date.getUTCMinutes())
      const seconds = this.pad2(date.getUTCSeconds())
      const dateCreate = year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds
      const msgtask = this._linkify(msg)
      const uid = uuidv4()

      const data = {
        uid_task: this.task.uid,
        uid_creator: this.user.current_user_uid,
        uid: uid,
        uid_msg: uid,
        uid_quote: this.currentAnswerMessageUid,
        date_create: dateCreate,
        deleted: 0,
        text: msg,
        msg: msgtask
      }

      this.$store.dispatch(MSG.CREATE_MESSAGE_REQUEST, data)
        .then(() => {
          const lastInspectorMessage = this.taskMessagesAndFiles[this.taskMessagesAndFiles.length - 2].uid_creator === 'inspector' ? this.taskMessagesAndFiles[this.taskMessagesAndFiles.length - 2] : false
          console.log('lastInspectorMessage: ', lastInspectorMessage)
          if (lastInspectorMessage) {
            this.$store.dispatch(INSPECTOR.ANSWER_INSPECTOR_TASK, {
              id: lastInspectorMessage.id,
              answer: 1
            }).then(() => {
              lastInspectorMessage.performer_answer = 1
            })
          }

          if (this.task.type === 2 || this.task.type === 3) {
            if ([TASK_STATUS.TASK_COMPLETED, TASK_STATUS.TASK_READY, TASK_STATUS.TASK_CANCELLED, TASK_STATUS.TASK_REJECTED].includes(this.task.status)) {
              this.$emit('changeValue', { status: TASK_STATUS.TASK_REFINE })
            }
          }
        })
      this.$emit('changeValue', { has_msgs: true })
      this.taskMsg = ''
    },
    setTaskFromQueue (uid) {
      this.$router.push('/task/' + uid)
      this.$store.state.tasks.taskFromQueue = uid
    },
    removeAnswerHint () {
      this.currentAnswerMessageUid = ''
    },
    readTask () {
      this.$emit('readTask')
    },
    pad2 (n) {
      return (n < 10 ? '0' : '') + n
    },
    onChangeComment (text) {
      const data = {
        uid: this.task.uid,
        value: text
      }
      this.$store.dispatch(TASK.CHANGE_TASK_COMMENT, data)
      this.$emit('changeValue', { comment: text })
    },
    getByNameOrEmail (employees) {
      return employees[this.task.uid_customer]?.name || this.task?.email_customer
    },
    _linkify (text) {
      return text.replace(/(lt?:\/\/[^\s]+)/g, '<a href="$1">$1</a>')
    },
    copyUrl (task) {
      copyText(`${window.location.origin}/task/${task.uid}`, undefined, (error, event) => {
        if (error) {
          console.log(error)
        } else {
          console.log(event)
        }
      })
    },
    removeTask (uid) {
      if (this.isPropertiesMobileExpanded) {
        this.$store.dispatch('asidePropertiesToggle', false)
      }
      this.$store.dispatch(TASK.REMOVE_TASK, uid)
    },
    // copypaste
    onPasteEvent: function (e) {
      const items = (e.clipboardData || e.originalEvent.clipboardData).items
      let loadFile = false
      for (const index in items) {
        const item = items[index]
        if (item.kind === 'file') {
          const blob = item.getAsFile()
          const formData = new FormData()
          formData.append('files', blob)
          const data = {
            uid_task: this.task.uid,
            name: formData
          }
          loadFile = true
          this.isloading = true
          this.$store.dispatch(FILES.CREATE_FILES_REQUEST, data).then(
            () => {
              this.isloading = false
              // ставим статус "на доработку" когда прикладываем файл
              if (this.task.type === 2 || this.task.type === 3) {
                if ([TASK_STATUS.TASK_COMPLETED, TASK_STATUS.TASK_READY, TASK_STATUS.TASK_CANCELLED, TASK_STATUS.TASK_REJECTED].includes(this.task.status)) {
                  if (((this.task.uid_customer === this.cusers?.current_user_uid) && ((this.task.status === TASK_STATUS.TASK_COMPLETED) || (this.task.status === TASK_STATUS.TASK_READY)))) {
                    this.$emit('changeValue', { status: TASK_STATUS.TASK_REFINE })
                  }
                }
              }
              // загрузка завершена - подписываемся опять
              this.$refs.taskMsgEdit.addEventListener('paste', this.onPasteEvent, { once: true })
            })
          setTimeout(() => {
            const elmnt = document.getElementById('content').lastElementChild
            elmnt.scrollIntoView({ behavior: 'smooth' })
          }, 100)
        }
      }
      if (!loadFile) {
        // не вставка файла - подписываемся опять
        this.$refs.taskMsgEdit.addEventListener('paste', this.onPasteEvent, { once: true })
      }
    },
    updateTask (event, task) {
      this.$store.dispatch(TASK.CHANGE_TASK_NAME, {
        uid: task.uid,
        value: this.name.replace(/\r?\n|\r/g, '')
      })
      if (task.name.length > 0) {
        if (task._justCreated) {
          task._addToList = true
          this.$store.dispatch(TASK.CREATE_TASK, task)
        } else {
          this.$store.dispatch(TASK.CHANGE_TASK_NAME, {
            uid: task.uid,
            value: this.name
          })
        }
        const data = {
          _isEditing: false
        }
        this.$emit('changeValue', data)
      } else if (task.name.length === 0) {
        if (task._justCreated) {
          if (this.isPropertiesMobileExpanded) {
            this.$store.dispatch('asidePropertiesToggle', false)
          }
          this.$store.commit(TASK.REMOVE_TASK, task.uid)
        } else {
          this.showConfirm = true
          // removeTask(task.uid)
        }
      }
    },
    deleteFiles (uid) {
      this.$store.dispatch(FILES.DELETE_FILE_REQUEST, { uid: uid })
    },
    deleteTaskMsg (uid) {
      this.$store.dispatch(MSG.DELETE_MESSAGE_REQUEST, { uid: uid })
        .then(() => {
          this.$store.state.tasks.selectedTask.has_msgs = true
          this.$store.state.taskfilesandmessages.messages.find(message => message.uid === uid).deleted = 1
        })
    },
    editTaskName (task) {
      const data = {
        _isEditable: this.user?.current_user_uid === task.uid_customer
      }
      this.$emit('changeValue', data)
    },
    clearTaskFocus (task) {
      if (task.name === '') {
        this.removeTask(task.uid)
      } else if (task.name !== '' && !task.enterPress) {
        this.updateTask(event, task)
      }
      if (this.isPropertiesMobileExpanded) {
        this.$store.dispatch('asidePropertiesToggle', false)
      }
      const data = {
        _isEditing: false
      }
      this.$emit('changeValue', data)
    },
    scrollDown () {
      this.showAllMessages = true
      this.infoComplete = true
      setTimeout(() => {
        const elem = document.getElementById('content').lastElementChild
        elem.scrollIntoView()
      }, 200)
    },
    getValidForeColor (foreColor) {
      if (foreColor && foreColor !== '#A998B6') return foreColor
      return ''
    },
    getValidBackColor (backColor) {
      if (backColor && backColor !== '#A998B6') return backColor
      return ''
    },
    countChecklist (checklist) {
      const data = {
        done: 0,
        undone: 0
      }
      // нормализуем перенос строки (разные на windows и на mac)
      const chlist = checklist.replaceAll('\r\n', '\n').replaceAll('\r', '\n').replaceAll('\n', '\r\n')
      for (const line of chlist.split('\r\n\r\n')) {
        data.undone++
        if (+line.split('\r\n')[0] === 1) {
          data.done++
        }
      }
      return data
    },
    changeValue (value) {
      this.$emit('changeValue', value)
    },
    nextTask () {
      this.$emit('nextTask')
    },
    changeFocus (uid, value) {
      this.$store.dispatch(TASK.CHANGE_TASK_FOCUS, {
        uid: uid,
        value: value
      })
        .then(() => {
          const data = {
            focus: value
          }
          this.$emit('changeValue', data)
        })
    },
    onChangeAccess (checkEmails) {
      let emails = checkEmails
      if (Array.isArray(checkEmails)) {
        emails = checkEmails.join('..')
      }
      const data = {
        uid: this.task.uid,
        value: emails
      }
      this.$store.dispatch(TASK.CHANGE_TASK_ACCESS, data)
        .then(() => {
          const data = {
            emails: emails
          }
          this.$emit('changeValue', data)
        })
      this.nextTask()
    },
    onClick (task) {
      this.$emit('clickTask', task)
    },
    reDo () {
      if (this.childrens?.length) {
        if (this.task.uid_performer === this.user.current_user_uid && this.task.uid_customer === this.user.current_user_uid) {
          this.lastSelectedStatus = TASK_STATUS.TASK_CANCELLED
        }
        if (this.task.uid_performer === this.user.current_user_uid && this.task.uid_customer !== this.user.current_user_uid) {
          this.lastSelectedStatus = TASK_STATUS.TASK_REJECTED
        }
        if (this.task.uid_performer !== this.user.current_user_uid && this.task.uid_customer === this.user.current_user_uid) {
          this.lastSelectedStatus = TASK_STATUS.TASK_REFINE
        }
        this.changeStatus(this.lastSelectedStatus, true)
        return
      }
      this.readTask()
      if (this.task.uid_performer === this.user?.current_user_uid && this.task.uid_customer === this.user?.current_user_uid) {
        this.$store.dispatch(TASK.CHANGE_TASK_STATUS, {
          uid: this.task.uid,
          value: TASK_STATUS.TASK_CANCELLED
        })
        this.$emit('changeValue', { status: TASK_STATUS.TASK_CANCELLED })
      }
      if (this.task.uid_performer === this.user?.current_user_uid && this.task.uid_customer !== this.user?.current_user_uid) {
        this.$store.dispatch(TASK.CHANGE_TASK_STATUS, {
          uid: this.task.uid,
          value: TASK_STATUS.TASK_REJECTED
        })
        this.$emit('changeValue', { status: TASK_STATUS.TASK_REJECTED })
      }
      if (this.task.uid_performer !== this.user?.current_user_uid && this.task.uid_customer === this.user?.current_user_uid) {
        this.$store.dispatch(TASK.CHANGE_TASK_STATUS, {
          uid: this.task.uid,
          value: TASK_STATUS.TASK_REFINE
        })
        this.$emit('changeValue', { status: TASK_STATUS.TASK_REFINE })
      }
      this.nextTask()
    },
    accept () {
      if (this.task.mode === 'slide') {
        this.nextTask()
        return
      }
      if (this.childrens?.length) {
        this.showStatusModal = true
        if ((this.task.uid_performer === this.user.current_user_uid && this.task.uid_customer === this.user.current_user_uid) ||
        (this.task.uid_performer !== this.user.current_user_uid && this.task.uid_customer === this.user.current_user_uid)) {
          this.lastSelectedStatus = TASK_STATUS.TASK_COMPLETED
        } else {
          this.lastSelectedStatus = TASK_STATUS.TASK_READY
        }
        return
      }
      this.readTask()
      if ((this.task.uid_performer === this.user?.current_user_uid && this.task.uid_customer === this.user?.current_user_uid) ||
        (this.task.uid_performer !== this.user?.current_user_uid && this.task.uid_customer === this.user?.current_user_uid)) {
        this.$store.dispatch(TASK.CHANGE_TASK_STATUS, {
          uid: this.task.uid,
          value: 1
        })
        this.$emit('changeValue', { status: TASK_STATUS.TASK_COMPLETED })
      } else {
        this.$store.dispatch(TASK.CHANGE_TASK_STATUS, {
          uid: this.task.uid,
          value: 5
        })
        this.$emit('changeValue', { status: TASK_STATUS.TASK_READY })
      }
      this.nextTask()
    },
    decline () {
      this.readTask()
      this.$store.dispatch(TASK.CHANGE_TASK_STATUS, {
        uid: this.task.uid,
        value: TASK_STATUS.TASK_PAUSED
      })
      this.$emit('changeValue', { status: TASK_STATUS.TASK_PAUSED })
      this.nextTask()
    },
    onReAssignToUser: function (userEmail) {
      const data = {
        uid: this.task.uid,
        value: userEmail
      }
      this.$store.dispatch(TASK.CHANGE_TASK_REDELEGATE, data)
        .then(
          resp => {
            this.$store.commit(TASK.SUBTASKS_REQUEST, resp.data)
          }
        )
    },
    onChangePerformer: function (userEmail) {
      console.log('onChangePerformer', userEmail)
      const user = this.$store.state.user.user
      const taskUid = this.task.uid
      const data = {
        uid: this.task.uid,
        value: userEmail
      }
      this.$store.dispatch(TASK.CHANGE_TASK_PERFORMER, data)
        .then(
          resp => {
            const data = {
              email_performer: resp.data.email_performer,
              perform_time: resp.data.perform_time,
              performerreaded: resp.data.performerreaded,
              uid_performer: resp.data.uid_performer,
              type: resp.data.type
            }
            this.changeValue(data)
          }
        )
      if (user?.current_user_email !== userEmail) {
        this.$store.commit(TASK.REMOVE_TASK, taskUid)
        this.$store.dispatch('asidePropertiesToggle', false)
      }
    },
    onAnswerMessage (uid) {
      this.currentAnswerMessageUid = uid
    },
    onChangeDates: function (begin, end) {
      const data = {
        uid_task: this.task.uid,
        str_date_begin: begin,
        str_date_end: end,
        reset: 0
      }
      this.$store.dispatch(TASK.CHANGE_TASK_DATE, data)
        .then(
          resp => {
            const data = {
              is_overdue: resp.is_overdue,
              term_user: resp.term,
              date_begin: resp.str_date_begin,
              date_end: resp.str_date_end
            }
            this.$emit('changeValue', data)
            this.readTask()
          })
    },
    changeStatus (status, isModalAnswer) {
      if (this.childrens?.length && !(isModalAnswer) && [TASK_STATUS.TASK_COMPLETED, TASK_STATUS.TASK_READY, TASK_STATUS.TASK_CANCELLED, TASK_STATUS.TASK_REJECTED].includes(status)) {
        this.lastSelectedStatus = status
        this.showStatusModal = true
        return
      }
      this.$store.dispatch(TASK.CHANGE_TASK_STATUS, {
        uid: this.task.uid,
        value: status
      }).then(() => {
        this.$emit('changeValue', { status: status })
        this.showStatusModal = false
        this.$emit('nextTask')
      })
    },
    cancelImproveRejectIcon (param) {
      return this.task.uid_customer === this.user?.current_user_uid && this.task.uid_performer !== this.user?.current_user_uid ? this.improve[param] : this.close[param]
    }
  }
}
</script>
