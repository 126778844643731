<template>
  <ModalBox
    :title="title"
    ok="Сохранить"
    :disabled="buttonSaveDisabled"
    @ok="onSave"
    @cancel="onCancel"
  >
    <div class="flex flex-col w-full">
      <span class="mb-1">Имя</span>
      <input
        ref="inputValue"
        v-model="name"
        type="text"
        :maxLength="maxLengthInput"
        class="bg-[#f4f5f7]/50 mb-3 rounded-[6px] focus:ring-0 border border-[#4c4c4d] focus:border-[#ff9123] w-full px-[14px] py-[11px] text-[14px] leading-[16px] text-[#4c4c4d] font-roboto"
      >
      <span class="mb-1">Телефон</span>
      <input
        v-model="phone"
        type="text"
        :maxLength="maxLengthInput"
        class="bg-[#f4f5f7]/50 mb-3 rounded-[6px] focus:ring-0 border border-[#4c4c4d] focus:border-[#ff9123] w-full px-[14px] py-[11px] text-[14px] leading-[16px] text-[#4c4c4d] font-roboto"
      >
      <span class="mb-1">Email</span>
      <input
        v-model="email"
        type="text"
        class="bg-[#f4f5f7]/50 mb-3 rounded-[6px] focus:ring-0 border border-[#4c4c4d] focus:border-[#ff9123] w-full px-[14px] py-[11px] text-[14px] leading-[16px] text-[#4c4c4d] font-roboto"
      >
      <span class="mb-1">Комментарий</span>
      <input
        v-model="comment"
        type="text"
        class="bg-[#f4f5f7]/50 rounded-[6px] focus:ring-0 border border-[#4c4c4d] focus:border-[#ff9123] w-full px-[14px] py-[11px] text-[14px] leading-[16px] text-[#4c4c4d] font-roboto"
      >
    </div>
  </ModalBox>
</template>

<script>
import ModalBox from '@/components/modals/ModalBox.vue'
import { uuidv4 } from '@/helpers/functions'

export default {
  components: {
    ModalBox
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['cancel', 'save'],
  data: () => ({
    name: '',
    phone: '',
    email: '',
    comment: ''
  }),
  computed: {
    maxLengthInput () {
      return '50'
    },
    buttonSaveDisabled () {
      return !this.name || !this.phone || !this.email || !this.comment
    }
  },
  methods: {
    onCancel () {
      this.$emit('cancel')
    },
    onSave () {
      const data = {
        uid: uuidv4(),
        name: this.name,
        phone: this.phone,
        email: this.email,
        comment: this.comment
      }
      this.$emit('save', data)
      this.onClear()
    },
    onClear () {
      this.name = ''
      this.phone = ''
      this.email = ''
      this.comment = ''
    }
  }
}
</script>

<style scoped></style>
