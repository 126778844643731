<template>
  <div class="text-center">
    <h1 class="text-5xl text-gray-600 font-bold mb-5">
      Карма - функция тарифа Бизнес+
    </h1>
    <h3 class="text-2xl mb-5">
      Узнайте свою личную продуктивность и продуктивность команды по выполненным поручениям в Лидертаск.
    </h3>

    <a
      href="https://www.leadertask.ru/alpha"
      class="w-[238px] font-[500] h-[40px] justify-center cursor-pointer bg-[#F2B679] text-[2E2E2E]  rounded-md hover:bg-slate-200 hover:text-[#422b14] text-xl font-bold py-2 px-12"
    >
      Купить
    </a>
  </div>
</template>
